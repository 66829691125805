<template>
  <div class="pt-container">
    <div class="pt-header">
      <div class="pt-title">
        Transações
        <span id="help-icon" class="icon-box">
          <HelpCircle class="icon stroke no-print" />
        </span>

        <b-tooltip target="help-icon" placement="bottom">
          Confira e gerencie todas as parcelas e informações de métodos de
          pagamento e recebimento das contas a receber e a pagar.
        </b-tooltip>
      </div>

      <div class="gap-action-buttons no-print">
        <Loading
          :class="{ reload: true, loading: loading }"
          @click="() => !loading && getInstallments()"
        />
        <b-button
          v-if="selectedToPay?.length"
          @click="openBillsToPayModal"
          class="wh-button"
          variant="outline-primary"
          outline
        >
          Pagar selecionado(s)
        </b-button>
        <b-button
          v-if="selectedToReceive?.length"
          @click="openBillsToReceiveModal"
          class="wh-button"
          variant="outline-primary"
          outline
        >
          Receber selecionado(s)
        </b-button>

        <b-button
          class="wh-button"
          variant="outline-primary"
          outline
          @click="getXlsx"
        >
          Download xlsx
        </b-button>

        <!-- <b-button
          class="wh-button"
          variant="outline-primary"
          outline
          @click="print"
        >
          Imprimir
        </b-button> -->
      </div>
    </div>

    <div class="row no-print">
      <div class="col-3">
        <b-form-group class="form-group">
          <label for="search">Descrição da conta</label>
          <b-form-input
            autocomplete="off"
            id="search"
            debounce="500"
            v-model="query"
            placeholder="Buscar por descrição"
          />
        </b-form-group>
      </div>

      <div class="col-2">
        <b-form-group id="bill_type">
          <label for="bill_type">Tipo de conta</label>
          <Autocomplete
            v-model="billType"
            :options="billTypes"
            placeholder="Selecionar"
          />
        </b-form-group>
      </div>

      <div class="col-2">
        <b-form-group id="field_category">
          <label for="field_category">Categoria</label>
          <Autocomplete
            v-model="category"
            :options="categories"
            placeholder="Selecionar"
          />
        </b-form-group>
      </div>

      <div class="col-2">
        <b-form-group class="form-group">
          <label for="search">Situação</label>
          <Autocomplete
            v-model="status_payment"
            :options="status_payments"
            placeholder="Selecionar"
            :situationSelected="situationSelected"
          />
        </b-form-group>
      </div>

      <div class="col-3">
        <b-form-group class="form-group">
          <div class="label">
            <label for="search" id="input-title">Período</label>

            <div class="status">
              <div class="status-input">
                <input
                  type="radio"
                  name="status"
                  value="service_date"
                  v-model="periodStatus"
                />Atend.
              </div>

              <div class="status-input">
                <input
                  type="radio"
                  name="status"
                  value="due_date"
                  v-model="periodStatus"
                />Venc.
              </div>

              <div class="status-input">
                <input
                  type="radio"
                  name="status"
                  value="payment_date"
                  v-model="periodStatus"
                />Receb.
              </div>
              <div class="status-input">
                <SwapVertical
                  class="order-icon"
                  @click="
                    periodOrder === 'desc'
                      ? (periodOrder = 'asc')
                      : (periodOrder = 'desc')
                  "
                />
              </div>
            </div>
          </div>

          <Periods
            @select="handlePeriod"
            :daySelected="daySelected"
          />
        </b-form-group>
      </div>
    </div>

    <div class="row no-print">
      <div class="col-2">
        <BeneficiarySelect
          v-model="beneficiary"
          :value="beneficiary"
          label="Favorecido"
        />
      </div>

      <div class="col-2">
        <PatientInput v-model="patient" />
      </div>

      <div class="col-2">
        <b-form-group class="form-group">
          <label for="search">Responsável</label>
          <Autocomplete
            v-model="creator_id"
            debounce="500"
            :options="creators"
            placeholder="Selecionar"
          />
        </b-form-group>
      </div>

      <div class="col-2">
        <b-form-group class="form-group">
          <label for="search">Centro de custo</label>
          <CheckboxSelect
            id="cost-center"
            optionLabel="label"
            trackBy="value"
            :value="selectedCostCenters"
            :options="costCenterOptions"
            v-on:onChange="changeSelectedCostCenters"
            :key="selectedCostCenters.length"
          />
        </b-form-group>
      </div>

      <div class="col-2">
        <b-form-group class="form-group">
          <label for="search">Pagamento</label>
          <CheckboxSelect
            id="payment-methods"
            placeholder="Selecionar"
            optionLabel="label"
            trackBy="value"
            v-model="payment_method"
            :value="selectedPaymentMethod"
            :options="payment_methods"
            v-on:onChange="changeSelectedPaymentMethods"
            :key="payment_methods.length"
          />
        </b-form-group>
      </div>

      <div class="col-2">
        <b-form-group id="field_bank_account">
          <label for="field_bank_account">Conta bancária</label>
          <Autocomplete
            v-model="bank_account"
            :options="bank_accounts"
            placeholder="Selecionar"
          />
        </b-form-group>
      </div>
    </div>

    <InstallmentsTable
      @selected-bills="selectedBills"
      :installments="installments"
      :onEdit="onEditBills"
      :getInstallments="getInstallments"
    />

    <div class="amount-position">
      <div class="toPay">
        <p>
          Valor total líquido a pagar
        </p>
        <p>
          <span>{{ parseNumberToMoney(sumToPay) }} </span>
        </p>
      </div>
      <div class="toPay">
        <p>
          Valor total bruto a pagar
        </p>
        <p>
          <span>{{ parseNumberToMoney(grossSumToPay) }} </span>
        </p>
      </div>
      
      <div class="toReceive">
        <p>
          Valor total líquido a receber
        </p>
        <p>
          <span>{{ parseNumberToMoney(sumToReceive) }} </span>
        </p>
      </div>
      
      <div class="toReceive">
        <p>
          Valor total bruto a receber
        </p>
        <p>
          <span>{{ parseNumberToMoney(grossSumToReceive) }} </span>
        </p>
      </div>
    </div>

    <div class="pagination-position no-print">
      <div>
        <b-button
          v-if="selectedToPay?.length"
          @click="openBillsToPayModal"
          class="wh-button"
          variant="outline-primary"
          outline
        >
          Pagar selecionado(s)
        </b-button>
        <b-button
          v-if="selectedToReceive?.length"
          @click="openBillsToReceiveModal"
          class="wh-button"
          variant="outline-primary"
          outline
        >
          Receber selecionado(s)
        </b-button>
      </div>

      <b-pagination
        size="sm"
        v-model="page"
        :total-rows="count"
        :per-page="limit"
        first-number
        last-number
      />
    </div>

    <BillsToPaySelectedPayModal
      identifier="selected-pay-modal"
      :installments="selectedToPay"
      :getBillsToPay="getInstallments"
      @onHide="onHideSelectedPayModal"
    />

    <SelectedBillsToReceiveModal
      :selectedInstallments="selectedToReceive"
      :onEdit="onEditBillsToReceive"
      :getBillsToReceive="getInstallments"
      @onHide="onHideSelectedReceiveModal"
    />
  </div>
</template>

<script>
import api from '../api'
import { EventBus } from '@/utils/eventBus'
import { saveAs } from 'file-saver'
import { parseNumberToMoney } from '@/utils/moneyHelper'
import { getCurrentClinic } from '../../../utils/localStorageManager'

export default {
  name: 'transactions-tab',
  components: {
    Autocomplete: () => import('@/components/Autocomplete'),
    BeneficiarySelect: () => import('@/components/General/BeneficiarySelect'),
    BillsToPaySelectedPayModal: () =>
      import('@/modules/financial/modals/BillsToPaySelectedPayModal'),
    CheckboxSelect: () => import('@/components/CheckboxSelect'),
    HelpCircle: () => import('@/assets/icons/help-circle.svg'),
    Loading: () => import('@/assets/icons/loading.svg'),
    InstallmentsTable: () =>
      import('@/modules/financial/components/InstallmentsTable'),
    PatientInput: () => import('@/components/General/PatientInput'),
    Periods: () => import('@/components/General/Periods'),
    SelectedBillsToReceiveModal: () =>
      import('@/modules/financial/modals/SelectedBillsToReceiveModal'),
    SwapVertical: () => import('@/assets/icons/swap-vertical.svg')
  },
  props: {
    onEditBillsToPay: Function,
    onEditBillsToReceive: Function
  },
  async mounted() {
    if (this.$route.query.v === 'v') {
      this.expirationType = 'today'
    }
    if (this.$route.query.p === 'sh') {
      const now = { start: this.moment().format('YYYY-MM-DD'), end: this.moment().format('YYYY-MM-DD') }
      this.handlePeriod(now)
      this.daySelected = true
      setTimeout(() => {
        this.situationSelected = true
      }, '3000')
    }
    await this.getInstallments()
    await this.getCategories()
    await this.getCreators()
    await this.getPaymentsMethods()
    await this.getBankAccounts()
    await this.getCostCenters()
    EventBus.$on(
      'reloadBillsToPay',
      async () => await this.getInstallments(true)
    )
  },

  data() {
    const clinic = getCurrentClinic()
    return {
      loading: false,
      selectedToPay: [],
      selectedToReceive: [],
      period: null,
      periodStatus: 'due_date',
      periodOrder: 'asc',
      query: '',
      page: 1,
      limit: 0,
      count: 0,
      clinicId: clinic.id,
      installments: [],
      billsToPay: [],
      category: null,
      categories: [],
      creator_id: null,
      creators: [],
      status_payment: null,
      start: null,
      end: null,
      expirationType: null,
      beneficiary: null,
      professional: null,
      professionals: [],
      patient: null,
      patients: [],
      payment_method: [],
      payment_methods: [],
      bank_account: null,
      bank_accounts: [],
      sumInstallmentAmountOfPage: 0,
      sumToPay: 0,
      sumToReceive: 0,
      grossSumToPay: 0,
      grossSumToReceive: 0,
      last_page: 0,

      status_payments: [
        { label: 'Vencido', value: 'expired' },
        { label: 'Pago', value: 'paid_out' },
        { label: 'Pendente', value: 'pending' },
        { label: 'Parcelado', value: 'installments' }
      ],

      costCenters: [],
      selectedPaymentMethod: [],
      selectedCostCenters: [],
      costCenterOptions: [],

      billType: null,
      billTypes: [
        { label: 'Contas a pagar', value: 'bill_to_pay' },
        { label: 'Contas a receber', value: 'bill_to_receive' }
      ],
      daySelected: false,
      situationSelected: false
    }
  },

  methods: {
    parseNumberToMoney,
    selectedBills(billsToPay, billsToReceive) {
      this.selectedToPay = billsToPay ?? []
      this.selectedToReceive = billsToReceive ?? []
    },
    openBillsToReceiveModal() {
      this.$bvModal.show('selected-bills-to-receive-modal')
    },
    openBillsToPayModal() {
      this.$bvModal.show('selected-pay-modal')
    },
    onEditBills(installment, hasBillItems = false) {
      if (installment?.payment?.bill?.type === 'bill_to_pay') {
        this.onEditBillsToPay(installment?.payment?.bill?.id)
      } else {
        this.onEditBillsToReceive(
          installment?.payment?.bill?.id,
          installment?.payment?.bill?.bill_items?.length
        )
      }
    },
    async getInstallments() {
      this.loading = true

      try {
        const filters = this.getFilters()
        const res = await api.getInstallments(this.clinicId, this.page, filters)
        this.installments = res.data.data
        this.count = res.data.total
        this.limit = res.data.per_page
        this.last_page = res.data.last_page

        this.sumToPay = res.data.sumToPay
        this.sumToReceive = res.data.sumToReceive
        this.grossSumToPay = res.data.grossSumToPay
        this.grossSumToReceive = res.data.grossSumToReceive

        if (this.$route.query.v === 'v') {
          this.$route.query.v = null
          this.expirationType = null
        }
      } catch (error) {
        this.$toast.error(error.message)
        this.sumToPay = 0
        this.sumToReceive = 0
        this.grossSumToPay = 0
        this.grossSumToReceive = 0
      } finally {
        this.loading = false
      }
    },
    getFilters() {
      return {
        type: this.billType,
        query: this.query,
        category: this.category,
        status_payment: this.status_payment,
        start: this.start,
        end: this.end,
        beneficiary: this.beneficiary?.id,
        patient: this.patient?.id,
        payment_method: this.payment_method,
        bank_account: this.bank_account,
        period_status: this.periodStatus,
        period_order: this.periodOrder,
        expirationType: this.expirationType,
        costCenters: this.costCenters,
        creator_id: this.creator_id
      }
    },
    async getCategories() {
      try {
        const response = await api.getCategories(this.clinicId, null, null, true)
        this.categories = response.data.map(category => ({
          ...category,
          label: `${category.name}`,
          value: `${category.id}`
        }))
      } catch (error) {
        throw new Error(400)
      }
    },

    async getProfessionals() {
      try {
        const response = await this.api.showClinicProfessionals(this.clinicId)
        this.professionals = response.data.map(professional => ({
          ...professional,
          label: `${professional.name}`,
          value: `${professional.id}`
        }))
      } catch (error) {
        throw new Error(400)
      }
    },

    async getCreators() {
      try {
        const response = await this.api.showClinicProfessionals(this.clinicId)
        this.creators = response.data.map(creator => ({
          ...creator,
          label: `${creator.name}`,
          value: `${creator.id}`
        }))
      } catch (error) {
        throw new Error(400)
      }
    },

    async getPaymentsMethods() {
      try {
        const response = await api.getPaymentMethodsBillToReceive(this.clinicId)
        this.payment_methods = response.data.map(payment => ({
          ...payment,
          label: `${payment.name}`,
          value: `${payment.id}`
        }))
      } catch (error) {
        throw new Error(400)
      }
    },

    async getBankAccounts() {
      try {
        const response = await api.getBankAccountBillToReceive(this.clinicId)
        this.bank_accounts = response.data.map(bank => ({
          ...bank,
          label: `${bank.name}`,
          value: `${bank.id}`
        }))
      } catch (error) {
        throw new Error(400)
      }
    },

    changeSelectedCostCenters(value) {
      this.costCenters = value.map(costCenter => costCenter.value)
    },

    changeSelectedPaymentMethods(value) {
      this.payment_method = value.map(paymentMethod => paymentMethod.value)
    },

    getCostCenters() {
      api
        .getCostCenters(this.clinicId)
        .then(res => {
          this.costCenterOptions = []
          res.data.map(center => {
            if (center.active) {
              this.costCenterOptions.push({
                value: center.id,
                label: center.name
              })
            }
          })
        })
        .catch(err => {
          this.$toast.error(err.message)
        })
    },

    handlePeriod(value) {
      this.start = value.start
      this.end = value.end
    },

    onHideSelectedPayModal() {
      this.selectedToPay = []
      this.getInstallments(true)
    },

    onHideSelectedReceiveModal() {
      this.selectedToReceive = []
      this.getInstallments(true)
    },

    print() {
      window.print()
    },
    getXlsx() {
      const isLoading = this.$loading.show()
      const filters = this.getFilters()
      api
        .getBillsExport(
          this.clinicId,
          filters
        )
        .then(res => {
          saveAs(
            new Blob([res.data], {
              type: 'application/vnd.ms-excel'
            }),
            'contas.xlsx'
          )
        })
        .catch(err => this.$toast.error(err.message))
        .finally(() => isLoading.hide())
    },
    getPageSum(type) {
      const sum = this.installments.reduce((acc, installment) => {
        if (installment?.payment?.bill?.type === type) {
          acc += installment?.amount
        }
        return acc
      }, 0)
      return this.parseNumberToMoney(sum)
    }
  },

  watch: {
    async page() {
      await this.getInstallments()
    },
    async query() {
      await this.getInstallments()
    },
    async billType() {
      await this.getInstallments()
    },
    async category() {
      await this.getInstallments()
    },
    async status_payment() {
      await this.getInstallments()
    },
    async start() {
      await this.getInstallments()
    },
    async beneficiary() {
      await this.getInstallments()
    },
    async patient() {
      await this.getInstallments()
    },
    async payment_method() {
      await this.getInstallments()
    },
    async bank_account() {
      await this.getInstallments()
    },
    async creator_id() {
      await this.getInstallments()
    },
    async periodStatus() {
      await this.getInstallments()
    },
    async periodOrder() {
      await this.getInstallments()
    },
    async costCenters() {
      await this.getInstallments()
    }
  }
}
</script>

<style scoped lang="scss">
.pt-container {
  padding: 30px 10px;

  .pt-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0 30px 0;

    .gap-action-buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
    }
  }

  .pt-title {
    display: inline-flex;
    align-items: center;
    font-family: 'Red Hat Display';
    font-weight: 500;
    font-size: 18px;
  }

  .icon {
    height: 24px;
    width: 24px;
    margin-left: 5px;
    stroke: var(--neutral-500);
  }

  .pagination-position {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding: 20px 0 !important;
  }

  .amount-position {
    display: flex;
    flex-direction: row-reverse;

    .toPay {
      background: var(--light-orange-200);
    }

    .toReceive {
      background: var(--states-success-soft-green);
    }

    div {
      padding: 8px 16px;
      border-radius: 8px;
      margin-left: 10px;
      justify-content: center;

      p {
        font-size: 16px;
        line-height: 150%;
        color: var(--type-active);

        span {
          font-weight: 700;
          color: var(--blue-500);
        }
      }
    }
  }

  label {
    width: 100%;
    font-family: 'Nunito Sans';
    font-weight: 700;
    font-size: 16px;
    color: var(--type-active);
    text-align: left !important;
    color: var(--dark-blue);
    margin-bottom: 4px;
  }

  .label {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: minmax(auto, 24px);

    .status {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;

      .status-input {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 13.5px;
        gap: 4px;
      }
    }
  }

  #input-title {
    font-family: 'Nunito Sans' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 150% !important;
    color: var(--type-active);
  }
}

.wh-button {
  color: var(--blue-500);
}

.order-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
  fill: var(--type-active);
}
</style>
